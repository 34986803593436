var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientData && _vm.$can("update", "clients")
    ? _c(
        "div",
        [
          _c("b-media", { staticClass: "mb-2" }, [
            _c("h4", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.clientData.display_name) + " ")
            ])
          ]),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.validateForm)
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Client Account ID",
                                        "label-for": "clientname"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "clientname",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "clientname",
                                                      state:
                                                        errors.length > 0
                                                          ? false
                                                          : null,
                                                      readonly: true,
                                                      name: "clientname"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .clientname,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "clientname",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.clientname"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Client Name",
                                        "label-for": "display_name"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "display_name",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "display_name",
                                                      readonly: true
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .display_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "display_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.display_name"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label:
                                          "Parent Client (leave blank for none)",
                                        "label-for": "parent_clientname"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "parent_clientname" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options: _vm.clientsList,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: true,
                                                      "input-id":
                                                        "parent_clientname"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .parent_clientname,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "parent_clientname",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.parent_clientname"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Services",
                                        "label-for": "services"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "servics" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.guard,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "guard",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.guard"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Guard Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.eyetrax,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "eyetrax",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.eyetrax"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Eye Trax Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.guardtour,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "guardtour",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.guardtour"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Guard Tour Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.tenant,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "tenant",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.tenant"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Tenant Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services
                                                            .privateinvestigation,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "privateinvestigation",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.privateinvestigation"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Private Investigation Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Admin Notes",
                                        "label-for": "adminnote"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "adminnote" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "adminnote",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .notes_admin,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "notes_admin",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.notes_admin"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Client Notes",
                                        "label-for": "clientnote"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "clientnote" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "clientnote",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .notes_client,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "notes_client",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.notes_client"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Public Notes",
                                        "label-for": "publicnote"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "publicnote" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "publicnote",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .notes_public,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "notes_public",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.notes_public"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Status",
                                        "label-for": "is_active"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "is_active",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      dir: _vm.$store.state
                                                        .appConfig.isRTL
                                                        ? "rtl"
                                                        : "ltr",
                                                      options:
                                                        _vm.statusOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: false,
                                                      "input-id": "is_active"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .is_active,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "is_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.is_active"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                      attrs: {
                                        variant: "primary",
                                        block: "",
                                        type: "submit"
                                      }
                                    },
                                    [_vm._v(" Save Changes ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1865874107
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }